<template>
    <el-dialog title="Configure Roster"
               width="400"
               top="1vh"
               :close-on-click-modal="false"
               :visible.sync="visible">
        <form method="POST" enctype="multipart/form-data" class="roster-form">
            <ul class="list-group list-group-flush">
                <li class="list-group-item">
                    <div class="row">
                        <div class="col">
                            <fg-input v-model="Roster.name" label="Roster Name" v-focus></fg-input>
                        </div>
                    </div>
                </li>
            </ul>
        </form>
        <div slot="footer">
            <n-button round type="info" @click.native="visible = false">Cancel</n-button>
            <n-button round type="success" @click.native="save" :disabled="$store.getters['loading']">Save Roster
            </n-button>
        </div>
    </el-dialog>
</template>
<script>
import { Dialog } from 'element-ui'

export default {
  name: 'roster-form',
  props: {
    value: Object,
    show: Boolean
  },
  components: {
    [Dialog.name]: Dialog
  },
  data () {
    return {
      Roster: {},
      visible: false
    }
  },
  methods: {
    save () {
      if (this.Roster.id === null) {
        this.$emit('create', this.Roster)
      } else {
        this.$emit('update', this.Roster)
      }
    }
  },
  computed: {},
  created () {
    this.Roster = this.value
  },
  watch: {
    /** V-Model */
    value (n) {
      this.Roster = n
    },
    Roster (n) {
      this.$emit('input', n)
    },
    /** Display form */
    visible (n) {
      this.$emit('update:show', n)
    },
    show (n) {
      this.visible = n
    }
  }
}
</script>
