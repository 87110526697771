<template>
    <span style="display:inline-block" class="float-right">
      <span class="cursor-pointer">
            <slot v-bind="{ visible }">
              <n-button round type="info" @click.native="openModal()" class="mr-2">Add New Controller</n-button>
            </slot>
        </span>

        <el-dialog title="Create New Controller"
                   :close-on-click-modal="false"
                   :visible.sync="visible">
            <form>
                <p><strong>The last UnionWare import was {{ facility.last_bue_update | utcDisplay('MM-DD-YYYY HH:mm:ss') }}.</strong></p>
                <p>Before adding controllers please verify the most recent import has occurred since the last pay period ended. Additionally,
                any bids for manually added controllers will not be attached to a member number and will not be recallable in future years.</p>

                <div class="row">
                    <div class="col">
                        <fg-input class="no-border"
                                  type="text"
                                  label="First Name"
                                  v-model="controller.FirstName">
                        </fg-input>
                    </div>
                    <div class="col">
                        <fg-input class="no-border"
                                  type="text"
                                  label="Last Name"
                                  v-model="controller.LastName">
                        </fg-input>
                    </div>
                    <div class="col">
                        <label class="mb-0">Area</label>
                        <el-select class="select-default d-block"
                                   placeholder="Area"
                                   v-model="controller.area_id">
                            <el-option v-for="a in areas" :key="a.id" :value="a.id" :label="a.name"></el-option>
                        </el-select>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <fg-input class="no-border"
                                  type="tel"
                                  label="Cell Phone"
                                  v-model="controller.cell_phone">
                        </fg-input>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <fg-input class="no-border"
                                  type="tel"
                                  label="Email"
                                  v-model="controller.email">
                        </fg-input>
                    </div>
                </div>

                <div class="row">
                    <div class="col-3">
                        <fg-input class="no-border"
                                  type="text"
                                  label="Init"
                                  :maxlength="2"
                                  v-model="controller.init">
                        </fg-input>
                    </div>
                    <div class="col">
                        <label class="mb-0">Notify Method</label>
                        <el-select class="select-default d-block"
                                   placeholder="Notification Method"
                                   v-model="controller.notify_method">
                            <el-option value="none" label="None"></el-option>
                            <el-option value="email" label="Email"></el-option>
                            <el-option value="text" label="Text" v-if="facility.allow_text"></el-option>
                        </el-select>
                    </div>
                    <div class="col-md-3 col-sm-12">
                        <label class="mb-0">Controller Type</label>
                        <el-select class="select-default d-block"
                                   placeholder="Controller Type"
                                   v-model="controller.employee_type_id">
                            <el-option v-for="type in employeeTypes" :key="type.id" :value="type.id" :label="type.type_name"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <fg-input class="no-border"
                                  type="text"
                                  label="Notes"
                                  v-if="is('arearep', area.id)"
                                  v-model="controller.notes"></fg-input>
                    </div>
                </div>
                                            <hr>
                <div class="row">
                    <div class="col">
                        <label class="w-100 pb-2">
                            Accumulated Hours
                            <el-tooltip>
                                <div class="text-left" slot="content">
                                    Enter number of accumulated hours the controller is eligible to bid.
                                </div>
                                <i class="fas fa-question-circle"></i>
                            </el-tooltip>
                            <fg-input class="no-border mt-2"
                                      type="number"
                                      v-if="is('arearep', area.id)"
                                      v-model="controller.accumulated_leave"></fg-input>
                        </label>
                    </div>
                    <div class="col">
                        <label class="w-100 pb-2">
                            Accrual Override
                            <el-tooltip>
                                <div class="text-left mt-2" slot="content">
                                    Specify the number of hours to use instead of the automatic calculation of accrual for the controller.
                                </div>
                                <i class="fas fa-question-circle"></i>
                            </el-tooltip>
                            <fg-input class="no-border"
                                      type="number"
                                      v-if="is('arearep', area.id)"
                                      v-model="controller.accrual_override"></fg-input>
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col text-center">
                        <n-button round type="success" @click.native="create()" class="mr-2">Save Changes</n-button>
                        <n-button round type="defautl" @click.native="visible = false">Cancel</n-button>
                    </div>
                </div>
            </form>
        </el-dialog>
    </span>
</template>

<script>
import { Dialog, Select, Option, Tooltip } from 'element-ui'
import { mapGetters } from 'vuex'

import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const MemberRepository = RepositoryFactory.get('member')

function init (areaId, employeeTypeId) {
  return {
    FirstName: null,
    LastName: null,
    cell_phone: null,
    init: null,
    notify_method: null,
    employee_type_id: employeeTypeId,
    area_id: areaId
  }
}

export default {
  name: 'add-u-w-controller',
  components: {
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip
  },
  props: {
    area_id: Number
  },
  data () {
    return {
      controller: {},
      visible: false
    }
  },
  methods: {
    create () {
      MemberRepository.create(this.controller)
        .then(r => {
          this.$emit('created', r.data)
          this.visible = false
        })
    },
    openModal () {
      this.$confirm('Manually added controllers do not have a member number and you must manually create a login account. <br>' +
        '<strong>Are you sure you want to continue?</strong>', 'Manually Create New Controller', {
        type: 'info',
        confirmButtonText: 'Create New Controller',
        dangerouslyUseHTMLString: true
      }).then(() => {
        this.visible = true
        this.controller = init(this.area.id, this.employeeTypes[0])
      })
    }
  },
  computed: {
    ...mapGetters({
      is: 'auth/is',
      facility: 'facility/facility',
      areas: 'area/areas',
      area: 'area/area',
      employeeTypes: 'facility/employeeTypes'
    })
  },
  created () {}

}
</script>
