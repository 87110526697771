 <template>
    <span>
        <drop-down tag="div" position="right" class="edit-link">
            <span slot="title" class="">
                <i class="fas fa-cog cursor-pointer"></i>
            </span>

            <a href="/#"
               class="dropdown-item py-0"
               @click.prevent="showForm(true)">
                <i class="fas fa-plus"></i> Create New Roster</a>

            <a href="#" class="dropdown-item py-0" @click.prevent="showForm()">
                <i class="fas fa-sliders-h"></i> Manage <b>{{ roster.name }}</b> Roster Settings
            </a>

            <a href="#"
               v-if="canDelete"
               class="dropdown-item py-0 text-danger"
               @click.prevent="deleteRoster()">
                <i class="fas fa-trash"></i> Delete Roster
            </a>
<!--            <add-u-w-controller :area_id="area.id" />-->
        </drop-down>

        <roster-form v-model="Roster"
                     :show.sync="showRosterForm"
                     @create="create"
                     @update="update" />
    </span>
</template>
<script>
import DropDown from '@/components/stateless/DropDown'
import RosterForm from './RosterForm'
import AddUWController from './AddUWController'
import { Popover } from 'element-ui'
import { mapGetters } from 'vuex'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const RosterRepository = RepositoryFactory.get('roster')

export default {
  name: 'roster-crud',
  components: {
    DropDown,
    RosterForm,
    AddUWController,
    [Popover.name]: Popover
  },
  props: {
    roster: {},
    canDelete: Boolean
  },
  data () {
    return {
      showRosterForm: false,
      Roster: {}
    }
  },
  methods: {
    create (Roster) {
      // Set additional fields
      Roster.year = this.facility.bid_year

      RosterRepository.create(Roster)
        .then((response) => {
          this.showRosterForm = false
          this.$emit('new-roster', response.data)
        })
    },
    deleteRoster () {
      this.$confirm('Are you sure you want to delete roster <strong>' + this.roster.name + '</strong>? If this roster is used in an active round all existing bids will be lost! <strong>USE CAUTION!</strong>', 'Warning', {
        type: 'warning',
        confirmButtonText: 'Delete Roster',
        dangerouslyUseHTMLString: true
      }).then(() => {
        RosterRepository.delete(this.roster.id)
          .then(() => {
            this.showRosterForm = false
            this.$emit('delete-roster', this.roster)
          })
      })
    },
    init () {
      return {
        id: null,
        area_id: this.area.id,
        name: ''
      }
    },
    showForm (creating = false) {
      if (creating) {
        // Create a new roster with a fresh instance
        this.Roster = this.init()
      } else {
        // Lose the reactivity to edit this roster
        this.Roster = JSON.parse(JSON.stringify(this.roster))
      }
      this.showRosterForm = true
    },
    update (Roster) {
      RosterRepository.update(Roster.id, Roster)
        .then((response) => {
          this.showRosterForm = false
          this.$emit('update-roster', response.data)
        })
    }
  },
  computed: {
    ...mapGetters({
      facility: 'facility/facility',
      leaveYearStart: 'facility/leaveYearStart',
      leaveYearEnd: 'facility/leaveYearEnd',
      area: 'area/area'
    })
  }
}
</script>
<style>
    #roster-container .edit-link {
        margin-top: -80px;
        margin-right: -10px;
    }

</style>
