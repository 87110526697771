<template>
    <div class="btn btn-success">
        {{ remaining }}
    </div>
</template>
<script>
    import {mapGetters} from 'vuex';

    export default {
        name: 'countdown',
        props: {
            start: [Object, String],
            duration: Number,
        },
        data() {
            return {}
        },
        methods: {},
        created() {
            // Dispatch the countdown
            this.$store.dispatch('countdown/start', {start: this.start, duration: this.duration});
        },
        computed: {
            ...mapGetters({
                remaining: 'countdown/remaining',
                status: 'countdown/status',
            }),
        },
        watch: {
            status(n) {
                if (n === 'expired')
                    this.$emit('expired');
            }
        }
    }
</script>
<style>
</style>
