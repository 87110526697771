  <template>
  <div>
    <div class="d-inline-block mr-5" v-if="viewingPastYear">
      <i class="fas fa-exclamation-triangle text-warning" />
      <strong class="font-italic mx-2 text-warning">VIEWING PREVIOUS YEAR</strong>
      <i class="fas fa-exclamation-triangle text-warning"/>
    </div>

    <el-dropdown trigger="click">
      <span class="el-dropdown-link">
        <n-button round
                  type="info"
                  class="bid-button small"
                  slot="title">
          <i class="fas fa-calendar-alt mr-1"></i> {{ viewYear || bidYear }} <i class="fas fa-caret-down ml-2" />
        </n-button>
      </span>

      <el-dropdown-menu>
        <el-dropdown-item @click.native="changeYear(year)">
          {{ year }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'change-leave-year',
  computed: {
    ...mapGetters({
      facility: 'facility/facility',
      viewingPastYear: 'facility/viewingPastYear',
      bidYear: 'facility/bidYear',
      viewYear: 'facility/viewYear',
      is: 'auth/is'
    }),
    year () {
      // Return previous year if no view set
      return this.viewYear !== this.bidYear ? this.bidYear : this.bidYear - 1
    }
  },
  methods: {
    changeYear (year) {
      this.$store.commit('facility/setViewYear', year)
      this.$emit('change', year)
    }
  }
}
</script>

<style scoped>

</style>
