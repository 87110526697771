<template>
    <div class="area-dropdown">
        <el-dropdown trigger="click">
            <span class="el-dropdown-link text-white font-italic font-weight-normal">
                {{ dropdownTitle }} <i class="fas fa-angle-down ml-1" />
            </span>
            <el-dropdown-menu slot="dropdown" size="medium" class="area-dropdown">
                <el-dropdown-item v-for="a in areas" :key="a.id" :class="{ 'selected': a.id === area.id }">
                    <router-link :to="{ name: 'Area Home', params: { facility: facility.id, area: a.slug } }">{{ a.name }}</router-link>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { Dropdown, DropdownItem, DropdownMenu } from "element-ui";

    export default {
        name: "area-dropdown",
        components: {
            [Dropdown.name]: Dropdown,
            [DropdownItem.name]: DropdownItem,
            [DropdownMenu.name]: DropdownMenu,
        },
        props: {},
        data() {
            return {}
        },
        methods: {},
        computed: {
            ...mapGetters({
                facility: 'facility/facility',
                areas: 'area/areas',
                area: 'area/area',
            }),
            dropdownTitle() {
                return _.isEmpty(this.area) ? 'Select Area' : this.area.name;
            },
        },
    }
</script>

<style scoped>
</style>
