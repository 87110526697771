<template>
    <multiselect
        class="w-100 mx-auto"
        v-model="member"
        tag-placeholder=""
        placeholder="Find Members (i.e., lastname OR firstname lastname)"
        track-by="id"
        label="display"
        :options="results"
        :multiple="false"
        :searchable="true"
        :loading="loading"
        :internal-search="false"
        :close-on-select="true"
        :clear-on-select="true"
        :options-limit="300"
        select-label=""
        :hide-selected="true"
        @search-change="findMembers"
        @select="changeUser"
        deselect-label="" />
</template>

<script>
import Multiselect from 'vue-multiselect'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const MemberRepository = RepositoryFactory.get('member')
const AuthRepository = RepositoryFactory.get('auth')

export default {
  name: 'bidder-search',
  props: {},
  components: {
    Multiselect
  },
  data () {
    return {
      loading: false,
      init: '',
      results: [],
      member: {}

    }
  },
  computed: {},
  methods: {
    findMembers: _.debounce(function (criteria) {
      // Make sure search criteria not empty
      if (criteria === '') return false

      this.loading = true
      MemberRepository.search({ criteria, filter_user: true })
        .then((response) => {
          this.results = response.data
        })
        .finally(() => {
          this.loading = false
        })
    }, 600),
    changeUser (member) {
      AuthRepository.getUserById(member.id)
        .then(r => {
          this.$store.dispatch('auth/mockUser', { user: r.data })
          this.$emit('done')
        })
        .catch(r => {
          this.$store.commit('alerts/newMessage', { type: 'error', message: r.data })
          this.results = []
        })
    }
  },
  watch: {
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
