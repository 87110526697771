<template>
    <el-popover placement="bottom"
                title="Enter Skip"
                width="500"
                trigger="manual"
                v-model="show">
        <div class="row d-block">
            <div class="col">
                <p class="m-0 p-2" v-if="bidder.round_type === 'leave'">
                    Please indicate whether you are skipping for the current round,
                    or if you are done bidding for the current year.
                </p>
                <p class="m-0 p-2" v-else>
                    You are skipping your bid opportunity for the Line Round.
                    <strong>
                        Are you sure you want to continue?
                    </strong>
                </p>

                <div class="text-center mx-0">
                    <n-button round
                              type="info"
                              class="bid-button m-0 mr-2"
                              @click.native="show = false">
                        <i class="fas fa-times mr-2"></i><b>Cancel</b>
                    </n-button>
                    <n-button round
                              type="primary"
                              class="bid-button m-0 mr-2"
                              @click.native="skip('round')">
                        <i class="fas fa-redo mr-2"></i><b>Skip Round</b>
                    </n-button>

                    <n-button v-if="bidder.round_type === 'leave'"
                              round
                              type="primary"
                              class="bid-button m-0"
                              @click.native="skip('end')">
                        <i class="fas fa-times-circle mr-2"></i><b>Done Bidding</b>
                    </n-button>
                </div>
            </div>
        </div>

         <n-button slot="reference"
                   round
                   type="danger"
                   size="sm"
                   class="bid-button small m-0 ml-1"
                   v-if="!hasSkipped"
                   @click.native="show = !show">
             <i class="fas fa-redo mr-1"></i>
             Skip
         </n-button>
    </el-popover>
</template>

<script>
    export default {
        name: "skip-button",
        components: {},
        props: {
            bidder: Object,
        },
        data() {
            return {
                show: false,
            }
        },
        methods: {
            skip(type) {
                this.$store.commit('bidder/skipBid', type);
                this.$store.dispatch('bidder/bid', this.bidder);
                this.show = false;
            },
        },
        computed: {
            hasSkipped() {
                return this.bidder.skipped;
            },
            hasSkippedToEnd() {
                return this.hasSkipped && !this.bidder.skipped_at;
            },
        },
    }
</script>

<style>

</style>
