<template>
    <span>
        <span class="cursor-pointer">
            <i class="fas fa-users text-primary" @click="visible = true"></i>
        </span>

        <span class="member-leave">
            <el-dialog :title="area.name + ' Controllers'"
                       :width="dialogWidth + '%'"
                       :close-on-click-modal="false"
                       :visible.sync="visible">
                <p class="mb-0">
                    Below is a list of all controllers currently assigned to the {{ area.name }} area. Controllers
                    on this list are used to calculate the leave slots required, clicking the delete icon removes
                    them from this area (not from the system). Use the button below to force a recalculation if
                    the numbers appear off.
                </p>
                <div class="text-center">
                   <n-button round
                             type="success"
                             class="w-25"
                             @click.native="refreshStats()">
                        Force Recalculate Stats
                    </n-button>
                </div>

                <table class="results table">
                    <tr class="subhead-light">
                        <td style="width: 275px">Name</td>
                        <td style="width: 50px" class="text-center">Init</td>
                        <td style="width: 150px" class="d-none d-lg-table-cell text-right">Accrued/Accumulated</td>
                    </tr>

                    <tr v-for="(member, i) in members" :key="member.id">
                        <td>
                            <i class="fas fa-trash text-danger cursor-pointer mr-1" @click="update(i)" />
                            {{ member.lname }}, {{ member.fname }} {{member.id}}
                        </td>
                        <td class="text-center text-uppercase">
                            <b>{{ member.init }}</b>
                        </td>
                        <td class="d-none d-lg-table-cell text-right">
                            {{ member.balance_accrued || 0 }} / {{ member.balance_accumulated || 0 }}
                        </td>
                    </tr>
                </table>
            </el-dialog>
        </span>
    </span>
</template>

<script>
import { Dialog } from 'element-ui'
import { mapGetters } from 'vuex'

import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const MemberRepository = RepositoryFactory.get('member')

export default {
  name: 'area-members',
  components: {
    [Dialog.name]: Dialog
  },
  props: {},
  data () {
    return {
      members: [],
      visible: false
    }
  },
  methods: {
    fetchMembers () {
      MemberRepository.getAreaMembers(this.bidYear, this.area.slug)
        .then(r => {
          this.members = r.data
        })
    },
    refreshStats () {
      this.$store.dispatch('area/fetchStats', true)
      this.visible = false
    },
    update (idx) {
      const member = this.members[idx]
      member.area_id = null
      MemberRepository.update(member.id, member)
        .then(() => {
          // Update the value
          this.members.splice(idx, 1)
          this.$emit('refresh')
        })
    }
  },
  computed: {
    ...mapGetters({
      bidYear: 'facility/bidYear',
      area: 'area/area'
    }),
    dialogWidth () {
      return this.screenSize !== 'lg' && this.screenSize !== 'xl' ? 90 : 50
    }
  },
  created () {},
  watch: {
    visible (n) {
      if (n && _.isEmpty(this.leave)) {
        this.fetchMembers()
      }
    }
  }
}
</script>
