<template v-if="roster">
    <el-dialog title="Create Roster Team"
               width="400"
               top="1vh"
               :close-on-click-modal="false"
               :visible.sync="visible">
        <form method="POST" enctype="multipart/form-data" class="roster-form" v-if="RosterTeam">
            <ul class="list-group list-group-flush">
                <li class="list-group-item">
                    <div class="row">
                        <div class="col">
                            <fg-input v-model="RosterTeam.name" label="Roster Team Name" v-focus></fg-input>
                        </div>
                    </div>
                </li>
            </ul>
        </form>
        <div slot="footer">
            <n-button round type="info" @click.native="RosterTeam = null">Cancel</n-button>
            <n-button round type="danger" @click.native="deleteTeam()" v-if="RosterTeam && RosterTeam.id">Delete Roster Team</n-button>
            <n-button round type="success" @click.native="save" :disabled="$store.getters['loading']">Save Roster
            </n-button>
        </div>
    </el-dialog>
</template>
<script>
import { Dialog } from 'element-ui'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const RosterTeamRepository = RepositoryFactory.get('rosterteam')

export default {
  name: 'roster-team-form',
  props: {
    roster: Object,
    value: Object
  },
  components: {
    [Dialog.name]: Dialog
  },
  data () {
    return {
      RosterTeam: {},
      visible: false
    }
  },
  methods: {
    create () {
      const data = {
        roster_id: this.roster.id,
        name: this.RosterTeam.name,
        order: this.roster.teams.length
      }
      RosterTeamRepository.create(data)
        .then(r => {
          this.$emit('create', r.data)
          this.RosterTeam = null
        })
    },
    deleteTeam () {
      RosterTeamRepository.delete(this.RosterTeam.id)
        .then(() => {
          this.$emit('delete', this.RosterTeam)
          this.RosterTeam = null
        })
    },
    save () {
      if (this.RosterTeam.id === undefined) {
        this.create()
      } else {
        this.update()
      }
    },
    update () {
      RosterTeamRepository.update(this.RosterTeam.id, this.RosterTeam)
        .then(r => {
          this.$emit('update', r.data)
          this.RosterTeam = null
        })
    }
  },
  computed: {},
  created () {
    this.RosterTeam = JSON.parse(JSON.stringify(this.value))
  },
  watch: {
    /** V-Model */
    value (n) {
      this.RosterTeam = n

      // Handle visibility
      this.visible = n !== null
    },
    RosterTeam (n) {
      if (n === null) {
        this.visible = false
        this.$emit('input', null)
      }
    }
  }
}
</script>
