<template>
    <card class="card-nav-tabs member-card mt-2" header-classes="card-header-warning">
        <div slot="header">
            {{ title }}
        </div>
        <div class="row mb-4">
            <div class="col">
                <form @keyup.enter="send()" @submit.prevent>
                    <div class="row">
                        <div class="col-sm-9 col-md-10">
                            <fg-input v-model="message" label="New Message"></fg-input>
                        </div>
                        <div class="col-sm-3 col-md-2 my-auto">
                            <n-button type="primary" class="mt-4" round block @click.native="send()">
                                Respond
                            </n-button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row mb-2" v-for="message in messages" :key="message.id">
            <div class="col">
                <card style="border-radius: 15px 15px"
                      class="mb-0 text-white" :class="[
                          (user_id !== message.user_id ? userClass : otherClass),
                          (screenSizeIs(['xs']) ? 'w-75' : screenSizeIs(['xl']) ? 'w-25' : 'w-50')
                      ]">
                    <div class="message_time text-right">
                        <small class="float-left font-weight-bold">{{ message.user.member.full_name }}</small>
                        <small class="font-italic">{{ message.created_at | utcDisplay('MM/DD/YY HH:mm') }}</small>
                        <span class="badge badge-warning ml-2" v-if="user_id !== message.user_id && !message.seen"><i class="fas fa-bell" /></span>
                    </div>

                    <p class="mb-1">{{ message.message }}</p>

                </card>
            </div>
        </div>
    </card>
</template>

<script>
export default {
  name: 'Chat',
  components: {},
  props: {
    title: {
      type: String,
      default: 'Chat History'
    },
    user_id: Number,
    messages: Array
  },
  data () {
    return {
      userClass: 'bg-primary',
      otherClass: 'd-flex ml-auto bg-info',
      message: null
    }
  },
  methods: {
    send () {
      // Create the response
      const message = {
        user_id: this.user_id,
        message: this.message
      }
      this.$emit('send', message)

      this.message = null
    }
  },
  computed: {},
  created () {}

}
</script>

<style scoped>

</style>
