<template>
  <multiselect
    class="w-100 mx-auto"
    v-model="facility"
    tag-placeholder=""
    placeholder="Select Facility"
    track-by="id"
    :options="facilities"
    :custom-label="label"
    :multiple="false"
    :searchable="true"
    :close-on-select="true"
    :clear-on-select="true"
    :options-limit="600"
    select-label=""
    :hide-selected="true"
    @select="go"
    deselect-label=""
    v-if="!loading" />
</template>

<script>
import Multiselect from 'vue-multiselect'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
import { Select, Option } from 'element-ui'
const FacilityRepository = RepositoryFactory.get('facilities')

/**
     * Create dropdown menu to select area
     */
export default {
  name: 'facility-dropdown',
  components: {
    Multiselect,
    [Select.name]: Select,
    [Option.name]: Option
  },
  props: {
    visible: Boolean
  },
  data () {
    return {
      facility: null,
      facilities: [],
      loading: true
    }
  },
  methods: {
    fetchFacilities () {
      FacilityRepository.index()
        .then((response) => {
          this.facilities = response.data

          // Set the current facility
          this.facility = _.find(this.facilities, { id: this.$store.state.facility.id })
          this.loading = false
        })
    },
    go (facility) {
      this.$router.push({ name: 'Facility Home', params: { facility: facility.id } })
      this.$emit('changed')
    },
    label ({ id, name }) {
      return `(${id.toUpperCase()}) ${name}`
    }
  },
  created () {
    this.fetchFacilities()
  }
}
</script>
<style></style>
