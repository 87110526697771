<template>
    <el-dialog title="Add Controllers"
               width="80%"
               top="1vh"
               :close-on-click-modal="false"
               :visible.sync="visible">
        <label>Find Controllers to Add</label>
        <multiselect
            class="w-100 mx-auto"
            v-model="members"
            tag-placeholder=""
            placeholder="Find Members (i.e., lastname OR firstname lastname)"
            track-by="id"
            label="display"
            :options="results"
            :multiple="true"
            :searchable="true"
            :loading="loading"
            :internal-search="false"
            :close-on-select="false"
            :clear-on-select="true"
            :options-limit="300"
            select-label=""
            :hide-selected="true"
            @search-change="findMembers"
            @select="results = []"
            deselect-label="" />

        <div slot="footer">
            <n-button round type="info" @click.native="visible = false">Cancel</n-button>
            <n-button round type="success" @click.native="save" :disabled="$store.getters['loading']">
                Add Controllers
            </n-button>
        </div>
    </el-dialog>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { Dialog } from 'element-ui'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const MemberRepository = RepositoryFactory.get('member')

export default {
  name: 'member-search',
  components: {
    Multiselect,
    [Dialog.name]: Dialog
  },
  props: {
    area_id: Number,
    roster_id: Number,
    show: Boolean,
    roster_team_id: Number
  },
  data () {
    return {
      loading: false,
      members: [],
      results: [],
      visible: false
    }
  },
  methods: {
    findMembers: _.debounce(function (criteria) {
      // Make sure search criteria not empty
      if (criteria === '') return false

      this.loading = true
      MemberRepository.search({ criteria: criteria })
        .then((response) => {
          this.results = response.data
        })
        .finally(() => {
          this.loading = false
        })
    }, 600),
    save () {
      const data = {
        members: this.members,
        area_id: this.area_id,
        roster_id: this.roster_id,
        roster_team_id: this.roster_team_id
      }
      MemberRepository.import(data)
        .then((response) => {
          this.$emit('add-members', response.data)
          this.members = []
          this.results = []
          this.visible = false
        })
    },
    newMember () {
      this.visible = false
      this.$emit('newMember')
    }
  },
  computed: {},
  watch: {
    /** Display modal */
    visible (n) {
      this.$emit('update:show', n)
      if (n === false) this.$emit('closed')
    },
    show (n) {
      this.visible = n
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
