<template>
    <span>
        <span class="mr-1">
            <n-button v-if="bidder.bid"
                      round
                      small
                      class="bid-button small m-0 text-white"
                      type="success"
                      disabled>
               <i class="fas fa-check-circle mr-2"></i> Entered
            </n-button>
            <n-button v-else-if="bidder.skipped && !bidder.skipped_by && round.number !== 1"
                      round
                      small
                      class="bid-button small m-0"
                      type="warning"
                      disabled>
               <i class="fas fa-times mr-2"></i>  Done Bidding
            </n-button>
            <span v-else-if="bidder.skipped">
                <n-button
                    round
                    small
                    class="bid-button small m-0"
                    type="warning"
                    disabled>
                    <i class="fas fa-redo mr-2"></i>  Skipped
                </n-button>
            </span>
            <n-button v-else-if="bidder.active"
                      round
                      small
                      class="bid-button small m-0 mr-3"
                      type="info"
                      disabled>
               <i class="fas fa-sync fa-spin mr-2"></i> Bidding
            </n-button>
        </span>

        <span v-if="bidder.bid || (bidder.skipped && bidder.skipped_by)">
            <i class="fas fa-history text-info cursor-pointer pull-right" @click="showHistory()"></i>

            <el-dialog :title="round.name + ' History for ' + bidder.full_name"
                       :visible.sync="showHistoryModal"
                       width="60%"
                       custom-class="p-0"
                       top="2vh">

                <ul class="list-group list-group-flush text-left">
                    <li class="list-group-item" v-for="(bid, bidIndex) in bids" :key="bid.id">
                        <h6>
                            <i class="fas fa-times-circle text-danger" v-if="bid.deleted_at"></i>
                            <i class="fas fa-check-circle text-success" v-else></i>
                            Bid ID: {{ bid.id }}
                            <n-button v-if="!bid.deleted_at && is('arearep', area.id)"
                                      type="danger"
                                      round
                                      size="sm"
                                      class="bid-button small ml-4"
                                      @click.native="deleteBid(bidIndex, bid)">
                                <i class="fas fa-trash"></i> Delete Bid
                            </n-button>
                        </h6>

                        <div v-if="bid.line.length">
                            <ul class="list-inline">
                                <li class="list-inline-item font-weight-bold">Line(s): </li>
                                <li class="list-inline-item" v-for="linebid in bid.line" :key="linebid.id">
                                    <i>{{ linebid.linegroup }}</i> {{ linebid.name }}
                                </li>
                            </ul>
                        </div>

                        <div v-if="bid.leave.length">
                            <ul class="list-inline">
                                <li class="list-inline-item font-weight-bold">Leave Days: </li>
                                <li class="list-inline-item" v-for="leavebid in bid.leave" :key="leavebid.id">
                                    {{ leavebid.calendar_id | utcDisplay('MMM D')}}
                                </li>
                            </ul>
                        </div>
                        <div v-if="bid.skip.length">
                            <h6 class="text-danger">{{ bid.skip[0].skip_type === "round" ? 'Skipped Round' : 'Skipped to End' }}</h6>
                        </div>

                        <table class="table results">
                            <thead>
                            <tr class="head">
                                <td>Action</td>
                                <td>Timestamp</td>
                                <td>User</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="log in bid.logs" :key="log.id">
                                <td>{{ log.action | capitalize }}</td>
                                <td>{{ log.timestamp }}</td>
                                <td>{{ log.init | uppercase }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </li>
                </ul>
            </el-dialog>
        </span>
    </span>
</template>

<script>
import { Dialog } from 'element-ui'
import { mapGetters } from 'vuex'
import { BidButton } from '@/components'

import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const BidRepository = RepositoryFactory.get('bid')

export default {
  name: 'member-bid-status',
  components: {
    [Dialog.name]: Dialog,
    [BidButton.name]: BidButton
  },
  props: {
    basePath: String,
    round: Object,
    bidder: Object
  },
  data () {
    return {
      showHistoryModal: false,
      bids: []
    }
  },
  computed: {
    ...mapGetters({
      is: 'auth/is',
      facility: 'facility/facility',
      area: 'area/area',
      employeeType: 'facility/employeeType'
    })
  },
  methods: {
    showHistory () {
      this.showHistoryModal = true

      if (!this.bids.length) {
        BidRepository.getBidHistory({
          member_id: this.bidder.member_id,
          round_id: this.round.id
        })
          .then(r => {
            this.bids = r.data
          })
      }
    },
    bidDayRange (range) {
      return this.$options.filters.dayRange(range[0].calendar_id, range[range.length - 1].calendar_id)
    },
    /** Delete a bid */
    deleteBid (bidIndex, bid) {
      // Cancel the bid
      BidRepository.delete(bid.id)
        .then(r => {
          // Update history
          this.$set(this.bids, bidIndex, r.data.bid_summary)

          // Update bidder in state
          _.forEach(r.data.bidders, bidder => {
            this.$store.commit('bidder/updateBidder', {
              bidder_id: bidder.id,
              changes: bidder
            })
          })
        })
    }
  },
  watch: {}
}
</script>
