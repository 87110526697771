<template>
    <span v-if="canManageBids && !biddingLocked && (isFacilityOpen || is(['admin', 'bidder']))" v-bind="$attrs">
        <template v-if="showBidButton">
<!--            <slot name="default" :isBidding="isBidding" :end-time="endTime">-->
                <span v-if="!bidder.active && !hasSkippedToEnd" class="cursor-pointer">
                     <n-button round
                               type="success"
                               class="bid-button"
                               :class="{'small': small}"
                               :disabled="!dependenciesMet"
                               @click.native="startBidding(bidder)">
                         <i class="fas fa-clipboard-check fa-md mr-1"></i>
                         Bid Now
                     </n-button>
                </span>
<!--            </slot>-->

<!--            <skip-button v-if="showSkip && canManageBids && !activeBidder"-->
<!--                         :bidder="this.eligibleBidder"-->
<!--                         :round-id="roundId"-->
<!--                         :small="small"></skip-button>-->
        </template>

        <skip-button v-if="eligibleToBid && !bidder.active && !bidder.skipped" v-bind="{ bidder }" />

        <!-- Manage eligibility of bidder -->
<!--        <template v-if="auth.isAdmin() && !activeBidder && !shortcut">-->
<!--            <n-button v-if="eligibleBidder"-->
<!--                      icon-->
<!--                      round-->
<!--                      type="danger"-->
<!--                      class="bid-button ml-1"-->
<!--                      :class="{'small': small}"-->
<!--                      :disabled="disabled"-->
<!--                      @click.native="cancelEligibleBidder">-->
<!--                <el-tooltip>-->
<!--                    <div class="text-left" slot="content">-->
<!--                        Removes eligibility to bid, but doesn't skip. Controller may be made eligible again after the next bidder bids.-->
<!--                    </div>-->
<!--                    <i class="fas fa-times"></i>-->
<!--                </el-tooltip>-->
<!--            </n-button>-->
<!--            <n-button v-else-if="round"-->
<!--                      icon-->
<!--                      round-->
<!--                      type="success"-->
<!--                      class="bid-button"-->
<!--                      :class="{'small': small}"-->
<!--                      :disabled="disabled"-->
<!--                      @click.native="createEligibleBidder">-->
<!--                <el-tooltip>-->
<!--                    <div class="text-left" slot="content">-->
<!--                        Make controller eligible to bid. Overrides automatic eligibility determination and sends notification immediately.-->
<!--                    </div>-->
<!--                    <i class="fas fa-clock"></i>-->
<!--                </el-tooltip>-->
<!--            </n-button>-->
<!--        </template>-->
    </span>
</template>

<script>
import moment from 'moment'
import SkipButton from '@/components/SkipButton'
import { mapGetters } from 'vuex'

var mixin = {
  computed: {
    /** Check if bid window has started */
    bidWindowHasStarted () {
      // Check the window start time
      const start = moment.utc(this.bidder.start)
      return moment.utc().isAfter(start)
    }
  }
}

/**
     * Determine the member status for the round and display appropriate icon
     */
export default {
  name: 'bid-button',
  components: {
    SkipButton
  },
  mixins: [mixin],
  props: {
    bidder: Object,
    round: Object,
    small: Boolean
  },
  data () {
    return {
      time: null
    }
  },
  computed: {
    ...mapGetters({
      is: 'auth/is',
      user: 'auth/user',
      facility: 'facility/facility',
      isFacilityOpen: 'facility/isFacilityOpen',
      area: 'area/area',
      active: 'bidder/active'
    }),
    /** Can only bid if no other active bidders */
    biddingLocked () {
      // No active bidders so controller can bid
      if (!this.active) return false

      // Active bidder in area, make sure it's this bidder
      return this.active && this.active.member_id !== this.bidder.member_id
    },
    /** Check that use has appropriate permissions to enter bids */
    canManageBids () {
      // Check permissions of user
      const can = this.is('bidaid')

      // Bidder can manage own bids as long as bid aids aren't required
      return can || (!this.area.use_bid_aid && this.bidder.up_to_bid && this.isUserBidder)
    },
    /** Check if member is eligible to start/continue bidding */
    eligibleToBid () {
      // Make sure bidder is eligible
      if (!this.bidder.up_to_bid) return false

      // Make sure round is active
      if (!this.round || this.round.status !== 'active') return false

      // If someone is bidding make sure it is this bidder
      return !this.active || (this.active && this.bidder.active)
    },
    dependenciesMet () {
      // Let line bidding through
      if (this.round.type === 'line') return true

      // Make sure leave bid has bid a line
      return this.round.type === 'leave' && this.bidder.line_bid
    },
    hasSkippedToEnd () {
      return this.bidder.skipped && !this.bidder.skipped_by && this.round.number !== 1
    },
    showBidButton () {
      // Make sure round is active
      if (this.round.status !== 'active') return false

      // Check eligibility and skips
      return this.eligibleToBid || (this.bidder.skipped && !this.hasSkippedToEnd)
    },
    isUserBidder () {
      return this.user && this.user.member_id === this.bidder.member_id
    }
  },
  methods: {
    startBidding () {
      this.$store.dispatch('bidder/startActiveBidder', this.bidder)
        .then(bidder => {
          if (bidder.round_type === 'leave') {
            this.$router.push({ name: 'Leave', params: { facility: this.facility.id, area: this.area.slug } })
          } else {
            this.$router.push({ name: 'Lines', params: { facility: this.facility.id, area: this.area.slug } })
          }
        })
    }
  },
  created () {},
  mounted () {},
  watch: {}
}
</script>
