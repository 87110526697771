<template>
    <div id="roster-container" class="mt-2">
        <member-search v-if="selectedRoster"
                       :show.sync="showMemberSearch"
                       :roster_id="selectedRoster.id"
                       :roster_team_id="selectedRosterTeamId"
                       :area_id="area.id"
                       @add-members="addMembers"
                       @new-member="showMemberCreate = true"
                       @closed="selectedRosterTeamId = null" />

        <roster-team-form v-model="RosterTeam"
                          :roster="this.selectedRoster"
                          @create="insertTeam"
                          @update="updateTeam"
                          @delete="deleteTeam" />

        <el-tabs type="border-card" v-model="activeRoster" ref="rosters">
            <el-tab-pane v-for="roster in rosters" :key="roster.id">
                <span slot="label">
                    {{ roster.name }}
                </span>

                <div class="row mb-2">
                    <div class="col">
                        <roster-crud :roster="roster"
                                     :can-delete="rosters.length > 1"
                                     v-if="is('arearep', area.id)"
                                     @new-roster="newRoster"
                                     @update-roster="updateRoster"
                                     @delete-roster="removeRoster" />

                        <h6 class="mb-0 d-inline-block">
                            {{ roster.name }}
                        </h6>
                        <p>
                            Add and remove controllers from rosters, then select the roster to use for bidding rounds.
                            Teams can be configured to bid in sequential order, or simultaneously when configuring rounds.
                            <i>Tip: New rosters will automatically sort all controllers assigned to this area by seniority.</i>
                        </p>
                        <p class="mt-3">
                          <b>Can't find a controller?</b> <i>You can manually add someone, however their data will not be
                          linked to their member number in future years and they will not be able to log into the system to bid.
                          This is because they do not yet have a member number to login to MyNATCA.</i> After adding the controller
                          you will need to them manually to the roster.
                        </p>
                        <add-u-w-controller :area_id="area.id"/>
                    </div>
                </div>

                <div class="row">
                    <div class="col">

                        <table class="results table-responsive">
                            <thead>
                            <tr>
                                <th>{{ roster.name }} Roster</th>
                            </tr>
                            </thead>

                            <draggable v-model="roster.teams"
                                       :key="'teamDrag-' + roster.id"
                                       tag="tbody"
                                       handle=".fa-grip-vertical"
                                       @choose="dragTeamActive = true"
                                       @unchoose="dragTeamActive = false"
                                       @change="orderTeams"
                                       group="roster">
                                <roster-team v-for="team in roster.teams"
                                             :key="team.id"
                                             :team-members.sync="members[team.id]"
                                             v-bind="{ team, dragTeamActive }"
                                             @order="orderRoster"
                                             @refresh="fetchMembers"
                                             @edit-team="RosterTeam = team"
                                             @update-member="updateMember"
                                             @search-members="searchMembers" />
                            </draggable>
                            <tr v-if="roster.teams.length === 0">
                                <td colspan="9" >Create a New Team to start building roster.</td>
                            </tr>

                        </table>
                        <span class="add-details mb-2" @click="RosterTeam = {}"><i class="fas fa-plus"></i> New Team</span>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>

    </div>
</template>
<script>
import RosterCrud from './RosterCrud'
import RosterTeam from './RosterTeam'
import RosterTeamForm from './RosterTeamForm'
import MemberSearch from './MemberSearch'
import { Tabs, TabPane } from 'element-ui'
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
import AddUWController from '@/views/roster/AddUWController'
const RosterRepository = RepositoryFactory.get('roster')
const MemberRepository = RepositoryFactory.get('member')

export default {
  components: {
    AddUWController,
    RosterCrud,
    RosterTeam,
    RosterTeamForm,
    MemberSearch,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    draggable
  },
  name: 'roster',
  data () {
    return {
      activeRoster: 0,
      members: {},
      rosters: {},
      showMemberSearch: false,
      showMemberCreate: false,
      dragTeamActive: false,
      selectedRosterTeamId: null,
      RosterTeam: null
    }
  },
  methods: {
    addMembers (members) {
      // Loop through all new controllers and add them to the array
      members.forEach(member => {
        this.members[this.selectedRosterTeamId].push(member)
      })
    },
    orderRoster ($event) {
      console.log($event)
      // If item removed
      if ($event && Object.keys($event).includes('removed')) return false

      // Set up changes variable
      const changes = {
        // roster_id: this.selectedRoster.id,
        ids: {},
        deleted: $event.delete ? $event.delete : null
      }

      // Loop through all teams and get order by id
      _.forEach(this.selectedRoster.teams, team => {
        changes.ids[team.id] = _.map(this.members[team.id], (m, i) => {
          return { index: i, id: m.id }
        })
      })

      RosterRepository.order(this.selectedRoster.id, changes)
    },
    orderTeams () {
      // Set up changes variable
      const changes = {
        ids: _.map(this.selectedRoster.teams, 'id')
        // roster_id: this.selectedRoster.id
      }

      RosterRepository.orderTeams(this.selectedRoster.id, changes)
    },
    insertTeam (team) {
      this.selectedRoster.teams.push(team)
      this.RosterTeam = null

      // Add the team to the members object to allow adding of new members
      this.members[team.id] = []
    },
    updateTeam (team) {
      _.find(this.selectedRoster.teams, { id: team.id }).name = team.name
      this.RosterTeam = null
    },
    deleteTeam (team) {
      // Find index of team being deleted
      const teams = this.selectedRoster.teams
      const i = _.findIndex(teams, { id: team.id })

      // Remove team from display
      teams.splice(i, 1)
      this.RosterTeam = null

      // Remove the team from the members array
      delete this.members[team.id]
    },
    updateMember (member) {
      // Don't update if the initials haven't changed
      if (member.init === '') return false
      MemberRepository.update(member.id, member)
    },
    fetchRosters () {
      return RosterRepository.index(this.facility.bid_year)
        .then((response) => {
          this.rosters = response.data
        })
    },
    fetchMembers () {
      return RosterRepository.getMembers(this.selectedRoster.id)
        .then((response) => {
          // Populate members by team_id
          this.members = _.groupBy(response.data, m => m.roster_team_id)

          // Make sure empty teams have an entry
          this.selectedRoster.teams.forEach(t => {
            if (!Object.keys(this.members).includes(String(t.id))) this.members[t.id] = []
          })
        })
    },
    searchMembers (id) {
      this.selectedRosterTeamId = id
      this.showMemberSearch = true
    },
    newRoster (roster) {
      const length = this.rosters.push(roster)
      this.activeRoster = `${length - 1}`
    },
    removeRoster () {
      this.rosters.splice(this.selectedRosterIdx, 1)
      this.activeRoster = '0'
    },
    updateRoster (roster) {
      this.rosters.splice(this.selectedRosterIdx, 1, roster)
    }
  },
  computed: {
    ...mapGetters({
      is: 'auth/is',
      facility: 'facility/facility',
      area: 'area/area',
      employeeTypes: 'facility/employeeTypes',
      employeeType: 'facility/employeeType',
      roleName: 'auth/roleName',
      crews: 'area/crews'
    }),
    selectedRoster () {
      return this.rosters[this.activeRoster]
    },
    selectedRosterIdx () {
      return parseInt(this.activeRoster)
    },
    /** Current roster ID */
    rosterId () {
      // Make sure roster exists
      if (_.isEmpty(this.rosters)) return null

      return this.rosters[this.activeTab].id
    }
  },
  created () {
    this.fetchRosters()
      .then(() => {
        this.fetchMembers(this.selectedRoster.id)
      })
  },
  watch: {
    activeRoster (n, o) {
      if (n !== o && !Number.isInteger(o)) { // Watch for change, but not on first load
        this.fetchMembers(this.rosters[parseInt(n)].id)
      }
    }
  },
  mounted () {
  }
}
</script>
<style>
    #roster-container .el-tabs__content {
        overflow: visible !important;
    }
</style>
