<template>
    <div class="row">
        <div class="col-12 mx-auto alert-box-master">
            <div v-for="(message, i) in messages" v-if="message.static" class="alert fade show alert-dismissible" :class="'alert-' + typeMap[message.type].class" role="alert" :key="i">
                <i :class="typeMap[message.type].icon" class="fa-lg mr-2"></i>
                {{ message.message }}
                <button type="button" class="close" data-dismiss="alert" @click="acknowledge(i)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";

    /**
     * Create alert box component
     */
    export default {
        name: 'alerts',
        data() {
            return {
                typeMap: {
                    error: {
                        class: 'danger',
                        icon: 'fas fa-skull',
                    },
                    success: {
                        class: 'success',
                        icon: 'fas fa-check-circle',
                    },
                    notify: {
                        class: 'warning',
                        icon: 'fas fa-exclamation-triangle',
                    }
                }
            }
        },
        computed: {
            ...mapGetters({
                messages: 'alerts/messages',
            }),
        },
        methods: {
            acknowledge(i) {
                this.$store.commit('alerts/deleteMessage', i);
            },
            loopMessages: async function (messages, callback) {
                for (let i = 0; i < messages.length; i++)  {
                    await callback(messages[i], i, messages);
                }
            },
            showMessage(m, i) {
                setTimeout(() => {
                    // Trigger the notification
                    this.$notify({
                        type: this.typeMap[m.type].class,
                        icon: this.typeMap[m.type].icon,
                        title: m.title ? m.title : '',
                        message: m.message,
                        timeout: 6000,
                    });
                }, i * 1000)
            },
            processMessages: async function () {
                this.$store.dispatch('alerts/fetchMessages')
                    .then(messages => {
                        this.loopMessages(messages, async(message, i) => {
                            await this.showMessage(message, i);
                        });
                    })
            },
        },
        created() {
            this.processMessages();
        },
        watch: {
            messages: _.debounce(function (n) {
                if (n.length) {
                    this.processMessages();
                }
            }, 1000),
        }
    }
</script>
