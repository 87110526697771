<template>
    <span v-hotkey="keymap">
      <el-dialog title="Super User Modal"
                 :visible="displaySuperModal"
                 :width="dialogWidth"
                 custom-class="p-0"
                 top="1vh">
          <h4 slot="header" class="title title-up text-white">Super User Links</h4>

          <div class="row col">
            <label>
                Select Facility<br>
                <facility-dropdown @changed="displaySuperModal = false"></facility-dropdown>
            </label>
          </div>

          <div class="row col">
              <label>View as Controller</label>
              <el-tag closable type="info" @close="$store.dispatch('auth/mockUser', { cancel: true })" v-if="mocking">
                {{ user.full_name }}
              </el-tag>
              <bidder-search @done="displaySuperModal = false"/>
          </div>
      </el-dialog>
    </span>
</template>

<script>
import { mapGetters } from 'vuex'
import FacilityDropdown from './FacilityDropdown'
import BidderSearch from './BidderSearch'
import { Dialog, Tag } from 'element-ui'

/**
 * Super User Modal Window
 */
export default {
  name: 'super-modal',
  components: {
    FacilityDropdown,
    BidderSearch,
    [Dialog.name]: Dialog,
    [Tag.name]: Tag
  },
  props: {},
  data () {
    return {
      displaySuperModal: false
    }
  },
  computed: {
    ...mapGetters({
      mocking: 'auth/mocking',
      user: 'auth/user'
    }),
    keymap () {
      return {
        'ctrl+esc': this.toggleWindow,
        esc: this.hideWindow
      }
    }
  },
  methods: {
    hideWindow () {
      this.displaySuperModal = false
    },
    toggleWindow () {
      this.displaySuperModal = true
    }
  },
  watch: {}
}
</script>
