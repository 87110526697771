<template>
  <tbody>
  <tr class="subhead cursor-pointer" :key="'tr-' + team.id">
    <td colspan="9" v-if="editable">
      <i class="fas fa-grip-vertical"></i>
      {{ team.name }}
      <i class="fas fa-edit cursor-pointer ml-2" @click="$emit('edit-team', team)"></i>

      <a href="#/" class="text-white float-right" @click.prevent="$emit('search-members', team.id)">
        <i class="fas fa-plus-circle"></i> Add Controller
      </a>
    </td>
    <td v-else colspan="9">
      {{ team.name }}
    </td>
  </tr>
  <tr>
    <td colspan="9" class="p-0">
      <table class="results table">
        <tr class="d-flex subhead-light" v-if="!dragTeamActive">
          <td class="col-7 col-sm-6 col-lg-4 col-xl-2">Name</td>
          <td class="d-none d-sm-table-cell col-sm-1 text-center">Init</td>
          <td class="d-none d-lg-table-cell col-lg-3 col-xl-1">Type</td>
          <td class="d-none d-xl-table-cell col-xl-1 text-center">NATCA BU</td>
          <td class="d-none d-xl-table-cell col-xl-1 text-center">CURRENT BU</td>
          <td class="d-none d-xl-table-cell col-xl-1 text-center">EOD</td>
          <td class="d-none d-xl-table-cell col-xl-1 text-center">SCD</td>
          <td class="col-5 col-lg-2 text-center">Role</td>
          <td class="d-none d-lg-table-cell col-lg-2 text-right">Accrued/Accum.</td>
        </tr>

        <draggable v-model="members"
                   :key="'drag-' + team.id"
                   handle=".fa-grip-vertical"
                   group="teams"
                   tag="tbody"
                   @change="$emit('order', $event)">
          <tr class="d-flex" v-for="(member, i) in members" :key="member.id" v-if="!dragTeamActive"
              :class="{'bg-lightblue': !member.init }">
            <td class="col-7 col-sm-6 col-lg-4 col-xl-2">
              <i v-if="editable" class="fas fa-grip-vertical cursor-pointer mr-1"></i>

              <el-tooltip class="item" v-if="is('super')">
                <p slot="content" class="m-0"><b>Member #: </b>{{ member.id }}</p>
                <i class="fas fa-user-astronaut cursor-pointer"/>
              </el-tooltip>

              <span :class="[member.member_status === 'Current Member' ? '' : 'text-danger']">
                {{ member.lname }}, {{ member.fname }}
              </span>

              <el-tooltip class="item" v-if="member.member_status !== 'Current Member'">
                <p slot="content" class="m-0">BUE is a non-member - texting disabled</p>
                <i class="fas fa-person-circle-xmark text-danger"/>
              </el-tooltip>

              <i class="d-sm-none">({{ member.init | uppercase }})</i>

              <span class="pull-right">
                  <el-tooltip v-if="member.facility_id !== facility.id || member.area_id !== area.id">
                      <div slot="content" class="m-0 text-left">
                          Member is not currently assigned to this facility and/or area. Click the edit
                          icon to the right to resolve this issue.

                          <ul class="list-unstyled mt-2">
                              <li><b>Facility</b> - {{ member.facility_id | uppercase }}</li>
                              <li><b>Area</b> - {{ findAreaName(member.area_id) | uppercase }}</li>
                          </ul>
                      </div>
                      <i class="fas fa-exclamation-triangle text-warning"></i>

                  </el-tooltip>

                  <el-tooltip v-if="member.user_id && is('arearep', area.id)">
                      <p slot="content" class="m-0">
                          View all activity for this user
                      </p>
                      <router-link
                          :to="{ name: 'Activity', params: { facility: $route.params.facility, user_id: member.user_id }}">
                          <i class="fas fa-eye mr-1"/>
                      </router-link>
                  </el-tooltip>

                  <edit-member :member="member"
                               @update-member="updateMember(member, $event)"
                               class="ml-1"/>

                  <i class="fas fa-trash cursor-pointer text-danger ml-1"
                     @click="deleteMember(member, i)"></i>
              </span>

            </td>
            <td class="d-none d-sm-table-cell col-sm-1 text-center text-uppercase" @click="editing = member.id">
              <inline-input v-if="editable"
                            v-model="member.init"
                            @blur="$emit('update-member', member)"
                            container-class="d-block w-100 h-100"
                            maxlength="2"
                            input-class="w-100 text-center text-uppercase"></inline-input>
              <b v-else>{{ member.init }}</b>
            </td>
            <td class="d-none d-lg-table-cell col-lg-3 col-xl-1">
              {{ employeeType(member.employee_type_id) ? employeeType(member.employee_type_id).type_name : 'No Profile'
              }}
            </td>
            <td class="d-none d-xl-table-cell col-xl-1 text-center">{{ member.NATCA_date | utcDisplay }}</td>
            <td class="d-none d-xl-table-cell col-xl-1 text-center">{{ member.BU_date | utcDisplay }}</td>
            <td class="d-none d-xl-table-cell col-xl-1 text-center">{{ member.EOD_date | utcDisplay }}</td>
            <td class="d-none d-xl-table-cell col-xl-1 text-center">{{ member.SCD_date | utcDisplay }}</td>
            <td class="col-5 col-lg-2 text-center">
              <!--                                <router-link v-if="member.user_id && is('arearep', area.id)" :to="{ name: 'Activity', params: { facility: $route.params.facility, user_id: member.user_id }}">-->
              <!--                                    <i class="fas fa-eye mr-1" />-->
              <!--                                </router-link>-->

              <!--                                <edit-user :member="member"-->
              <!--                                           v-if="member.role_id"-->
              <!--                                           @update-member="updateMemberRole(member, $event)">-->
              <!--                                    {{ roleName(member.role_id) }}-->
              <!--                                </edit-user>-->
              <span v-if="member.role_id">{{ roleName(member.role_id) }}</span>
              <i v-else class="m-0">Not Registered</i>
            </td>
            <td class="d-none d-lg-table-cell col-lg-2 text-right">
              {{ member.balance_accrued || 0 }} / {{ member.balance_accumulated || 0 }}

              <member-leave class="ml-2"
                            :name="member.full_name"
                            @refresh="$emit('refresh')"
                            :member_id="member.id"/>
            </td>
          </tr>
        </draggable>

      </table>
    </td>
  </tr>
  </tbody>
</template>

<script>
import { EditMember, MemberLeave } from '@/components'
import draggable from 'vuedraggable'
import { InlineInput } from '@/components/stateless'
import { mapGetters } from 'vuex'

export default {
  name: 'roster-team',
  components: {
    EditMember,
    MemberLeave,
    draggable,
    InlineInput
  },
  props: {
    team: Object,
    teamMembers: Array,
    dragTeamActive: Boolean,
    editable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      members: this.teamMembers
    }
  },
  methods: {
    findAreaName (areaID) {
      const area = _.find(this.areas, { id: areaID })
      return area ? area.name : ''
    },
    updateMember (member, update) {
      Object.assign(member, update)
    },
    updateMemberRole (member, roleID) {
      member.role_id = roleID
    },
    deleteMember (member, idx) {
      this.members.splice(idx, 1)
      this.$emit('order', { delete: member.id })
    }
  },
  computed: {
    ...mapGetters({
      is: 'auth/is',
      facility: 'facility/facility',
      areas: 'area/areas',
      area: 'area/area',
      employeeType: 'facility/employeeType',
      roleName: 'auth/roleName'
    })
  },
  watch: {
    /** members prop */
    members (n) {
      this.$emit('update:teamMembers', n)
    },
    teamMembers (n) {
      this.members = n
    }
  }
}
</script>

<style scoped>

</style>
