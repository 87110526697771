<template>
    <span>
        <span class="cursor-pointer">
            <i class="fas fa-calculator text-primary" @click="visible = true"></i>
        </span>

        <span class="member-leave text-left">
            <el-dialog :title="name + ' Leave'"
                       :width="dialogWidth + '%'"
                       :close-on-click-modal="false"
                       :visible.sync="visible">
                <div class="row col header">
                    <h5 class="m-0">Accrued Leave</h5>
                </div>
                <div class="row">
                    <div class="col-sm-6 col-md-3">
                        <h6 class="leave-desc">Earning Category</h6>
                        <i>Start:</i> {{  leave.category_start }}
                        <i>End:</i> {{ leave.category_end }}
                    </div>
                    <div class="col-sm-6 col-md-3">
                        <h6 class="leave-desc">Category Change</h6>
                        <span v-if="leave.category_pp" class="category-change">
                            <i>Pay Period:</i> {{ leave.category_pp }} ({{ leave.category_effective_date | utcDisplay }})
                        </span>
                        <span v-else>No Change</span>
                    </div>
                    <div class="col-sm-6 col-md-3">
                        <h6 class="leave-desc">Mandatory Retirement</h6>
                        <span v-if="leave.mandatory_retirement_pp">
                            <i>Pay Period:</i> {{ leave.mandatory_retirement_pp }} ({{ leave.mandatory_retirement_date | utcDisplay }})
                        </span>
                        <span v-else>N/A</span>
                    </div>
                    <div class="col-sm-6 col-md-3">
                        <h6 class="leave-desc">Retirement Accrual</h6>
                        <span v-if="leave.retiree_accrual_override">
                            {{ leave.retiree_accrual_override }}
                        </span>
                        <span v-else>N/A</span>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-sm-6 col-md-3">
                        <h6 class="leave-desc">
                            Beginning Balance

                            <i class="fas fa-edit text-success cursor-pointer" v-if="!showAccrual && canEdit" @click="showAccrual = true" />
                            <i class="fas fa-save text-success cursor-pointer" v-if="showAccrual" @click="save" />
                            <i class="fas fa-times text-danger cursor-pointer ml-2" v-if="showAccrual" @click="showAccrual = false" />
                        </h6>
                        <span v-if="leave.accrual_override">
                            {{ leave.accrual_override || 0 }}
                            <i>Calculated:</i> {{ leave.accrual_hours || 0 }}
                        </span>
                        <span v-else>
                            {{ leave.balance_accrued_beginning }}
                        </span>
                        <span v-if="showAccrual">
                            <label class="w-100 pb-2">
                                Accrual Override
                                <el-tooltip>
                                    <div class="text-left mt-2" slot="content">
                                        Specify the number of hours to use instead of the automatic calculation of accrual for the controller.
                                    </div>
                                    <i class="fas fa-question-circle"></i>
                                </el-tooltip>
                                <fg-input class="no-border"
                                          type="number"
                                          v-model.number="updates.accrual_override"></fg-input>
                            </label>
                        </span>
                    </div>
                    <div class="col-sm-6 col-md-3">
                        <h6 class="leave-desc">Hours Bid</h6>
                        {{ leave.bid_accrued || 0 }}
                    </div>
                    <div class="col-sm-6 col-md-3">
                        <h6 class="leave-desc">Holidays Bid</h6>
                        {{ leave.bid_accrued_holiday || 0 }}
                    </div>
                    <div class="col-sm-6 col-md-3">
                        <h6 class="leave-desc">Balance</h6>
                        {{ leave.balance_accrued || 0 }}
                    </div>
                </div>

                <div class="row col header">
                    <h5 class="m-0">Accumulated Leave</h5>
                </div>
                <div class="row">
                    <div class="col-sm-6 col-md-3">
                        <h6 class="leave-desc">
                            Beginning Balance

                            <i class="fas fa-edit text-success cursor-pointer" v-if="!showAccumulated && canEdit" @click="showAccumulated = true" />
                        <i class="fas fa-save text-success cursor-pointer" v-if="showAccumulated" @click="save" />
                        <i class="fas fa-times text-danger cursor-pointer ml-2" v-if="showAccumulated" @click="showAccumulated = false" />
                        </h6>
                        {{ leave.balance_accumulated_beginning || 0 }}

                        <span v-if="showAccumulated">
                            <label class="w-100 pb-2">
                                Accumulated Hours
                                <el-tooltip>
                                    <div class="text-left" slot="content">
                                        Enter number of accumulated hours the controller is eligible to bid.
                                    </div>
                                    <i class="fas fa-question-circle"></i>
                                </el-tooltip>
                                <fg-input class="no-border mt-2"
                                          type="number"
                                          v-if="is('arearep', area.id)"
                                          v-model.number="updates.balance_accumulated_beginning"></fg-input>
                            </label>
                        </span>
                    </div>
                    <div class="col-sm-6 col-md-3">
                        <h6 class="leave-desc">Hours Bid</h6>
                        {{ leave.bid_accumulated || 0 }}
                    </div>
                    <div class="col-sm-6 col-md-3">
                        <h6 class="leave-desc">Holidays Bid</h6>
                        {{ leave.bid_accumulated_holiday || 0 }}
                    </div>
                    <div class="col-sm-6 col-md-3">
                        <h6 class="leave-desc">Balance</h6>
                        {{ leave.balance_accumulated || 0 }}
                    </div>
                </div>

                <div class="row col header">
                    <h5 class="m-0">Summary</h5>
                </div>
                <div class="row">
                    <div class="col-sm-6 col-md-3">
                        <h6 class="leave-desc">Beginning Balance</h6>
                        {{ leave.balance_accrued_beginning + leave.balance_accumulated_beginning || 0 }}
                    </div>
                    <div class="col-sm-6 col-md-3">
                        <h6 class="leave-desc">Hours Bid</h6>
                        {{ leave.bid_hours || 0 }}
                    </div>
                    <div class="col-sm-6 col-md-3">
                        <h6 class="leave-desc">Holidays Bid</h6>
                        {{ leave.bid_holiday_hours || 0 }}
                    </div>
                    <div class="col-sm-6 col-md-3">
                        <h6 class="leave-desc">Balance</h6>
                        {{ leave.balance || 0 }}
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <n-progress :type="progressPercent <= 25 ? 'warning' : 'success'"
                                    label="Leave Remaining"
                                    :height="10"
                                    :value="progressPercent || 0"
                                    show-value
                                    class="my-2 pr-3" />
                    </div>
                </div>
            </el-dialog>
        </span>
    </span>
</template>

<script>
import { Dialog } from 'element-ui'
import { mapGetters } from 'vuex'
import { Progress as NProgress } from '@/components/stateless'

import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const MemberLeaveRepository = RepositoryFactory.get('memberleave')

export default {
  name: 'member-leave',
  components: {
    [Dialog.name]: Dialog,
    NProgress
  },
  props: {
    name: String,
    member_id: Number
  },
  data () {
    return {
      leave: {},
      updates: {
        accrual_override: null,
        balance_accumulated_beginning: null
      },
      showAccrual: false,
      showAccumulated: false,
      visible: false
    }
  },
  methods: {
    fetchMemberLeave () {
      MemberLeaveRepository.get(this.member_id, this.bidYear)
        .then(r => {
          this.leave = r.data
        })
    },
    save () {
      MemberLeaveRepository.update(this.leave.id, this.updates)
        .then(r => {
          // Update the value
          this.leave = r.data
          this.$store.dispatch('bidder/refresh', r.data.member_id)
          this.$emit('refresh', r)

          // Close the input boxes
          this.showAccrual = false
          this.showAccumulated = false
        })
    }
  },
  computed: {
    ...mapGetters({
      is: 'auth/is',
      bidYear: 'facility/bidYear',
      area: 'area/area',
      bidMode: 'bidder/bidMode'
    }),
    canEdit () {
      return this.is('arearep', this.area.id) && !this.bidMode
    },
    dialogWidth () {
      return this.screenSize !== 'lg' && this.screenSize !== 'xl' ? 90 : 50
    },
    progressPercent () {
      return Math.ceil((this.leave.balance / (this.leave.balance_accrued_beginning + this.leave.balance_accumulated)) * 100)
    }
  },
  created () {},
  watch: {
    visible (n) {
      if (n) {
        this.fetchMemberLeave()
      }
    }
  }
}
</script>
